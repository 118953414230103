import React, { useContext, useEffect, useMemo, useState } from 'react';
import { getDefaultRiskStatus } from './FilterMenu';
import { Box, Container, Header, SpaceBetween } from '@amzn/awsui-components-react';
import useRisks from './react-query/useRisks';
import { useParams } from 'react-router';
import ReviewTabs from './ReviewTabs';
import { NotificationsContext } from '../../common/Notifications';
import { toResourceRevisionsItems, toRiskTableItems } from './react-query/translation';
import { toFilteredRiskTableItems, someRiskStatus } from './table-definitions/risk-table';
import { RiskColumnDefinitionData } from './table-definitions/common';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { RisksContext } from './context/RisksContext';
import {
  ResourceRevisionList,
  UpdateRiskStatusList
} from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';
import useBatchUpdateRiskStatusMutation from './react-query/useBatchUpdateRiskStatusMutation';
import { useSearchParams } from 'react-router-dom';
import {
  allSelectOption,
  SearchParams,
  riskDefaultDisplayOption,
  getReviewBadges
} from './table-definitions/filter-menu';
import useResourceRevisions from './react-query/useResourceRevisions';
import { RiskStatus } from '../../common/RiskStatus';

type FilterOption = {
  optionValue?: string;
  searchParamName: string;
};

const Review = () => {
  const { reviewId } = useParams();
  const notificationsControls = useContext(NotificationsContext);
  const [tableItems, setTableItems] = useState([] as RiskColumnDefinitionData[]);
  const [resourceRevisionItems, setResourceRevisionItems] = useState([] as ResourceRevisionList);
  const [isFiltering, setIsFiltering] = useState(false);
  const [isMutating, setIsMutating] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [riskStatusFilterValue, setRiskStatusFilterValue] = useState<OptionDefinition>(allSelectOption);
  const [ruleFilterValue, setRuleFilterValue] = useState<OptionDefinition>(allSelectOption);
  const [resourceTypeFilterValue, setResourceTypeFilterValue] = useState<OptionDefinition>(allSelectOption);
  const [riskDisplayControlValue, setRiskDisplayControlValue] = useState<string>(riskDefaultDisplayOption.id);

  /**
   * Update the query search param using the filter option.
   *
   * @param filter FilterOption object
   */
  const updateSearchParams = (filter: FilterOption) => {
    if (filter.optionValue) {
      searchParams.set(filter.searchParamName, filter.optionValue);
      setSearchParams(searchParams);
    }
  };

  const {
    data: riskData,
    hasNextPage: hasMoreRisks,
    isLoading: isLoadingRisks,
    refetch: refetchRisks,
    fetchNextPage: fetchMoreRisks
  } = useRisks(
    String(reviewId),
    1000,
    () => {
      hasMoreRisks !== false && fetchMoreRisks();
    },
    (err) => notificationsControls.addAWSErrorNotification(err, 'Failed to load risks')
  );
  const {
    data: resourceRevisionData,
    isLoading: isLoadingResourceRevisions,
    hasNextPage: hasMoreResourceRevisions,
    fetchNextPage: fetchMoreResourceRevisions
  } = useResourceRevisions(
    String(reviewId),
    undefined,
    () => {
      hasMoreResourceRevisions !== false && fetchMoreResourceRevisions();
    },
    (err) => notificationsControls.addAWSErrorNotification(err, 'Failed to load resource revisions')
  );

  const memoizedRiskItems = useMemo(() => {
    const riskItems = toRiskTableItems(riskData);
    setTableItems(riskItems);
    return riskItems;
  }, [riskData]);

  const memoizedResourceRevisionItems = useMemo(() => {
    const resourceRevisions = toResourceRevisionsItems(resourceRevisionData);
    setResourceRevisionItems(resourceRevisions);
    return resourceRevisions;
  }, [resourceRevisionData]);

  const batchUpdateRiskStatusMutation = useBatchUpdateRiskStatusMutation(
    (data) => {
      if (data.successes.length) {
        notificationsControls.addSuccessNotification(
          `Successfully updated status for ${data.successes.length} risk(s)`
        );
      }
      // Because this is a batch operation there can be some error under "failures" in the response
      // even if the call succeeds
      if (data.failures.length) {
        const batchUpdateUniqueErrorsMap = data?.failures.reduce((errors, item) => {
          return errors.set(
            item.errorMessage,
            (errors.get(item.errorMessage) || new Array<string>()).concat([item.resourceId])
          );
        }, new Map<string, string[]>());
        notificationsControls.addBatchErrorNotification(
          batchUpdateUniqueErrorsMap,
          `Failed to update ${data.failures.length} risk(s)`
        );
      }
      // Error of refetch is handled in the useRisk hook
      refetchRisks().finally(() => setIsMutating(false));
    },
    (err) => {
      notificationsControls.addAWSErrorNotification(err, 'Failed to update risks');
      setIsMutating(false);
    }
  );

  const filterRisks = (
    statusValue: OptionDefinition,
    ruleValue: OptionDefinition,
    resourceTypeValue: OptionDefinition
  ) => {
    setRiskStatusFilterValue(statusValue);
    setRuleFilterValue(ruleValue);
    setResourceTypeFilterValue(resourceTypeValue);

    updateSearchParams({ optionValue: statusValue.value, searchParamName: SearchParams.RISK_STATUS });
    updateSearchParams({ optionValue: ruleValue.value, searchParamName: SearchParams.RULE });
    updateSearchParams({ optionValue: resourceTypeValue.value, searchParamName: SearchParams.RESOURCE_TYPE });

    setIsFiltering(true);
    const filteredRisks = toFilteredRiskTableItems(
      memoizedRiskItems,
      memoizedResourceRevisionItems,
      statusValue,
      ruleValue,
      resourceTypeValue
    );
    setTableItems(filteredRisks);
    setIsFiltering(false);
  };

  const riskContextProviderValue = {
    updateRisks: (status: string, selectedRisks: UpdateRiskStatusList) => {
      setIsMutating(true);
      batchUpdateRiskStatusMutation.mutate({
        reviewId: String(reviewId),
        updateRiskStatusList: selectedRisks.map((item) => {
          const { resourceId, ruleId, dedupeToken } = item;
          return {
            resourceId,
            ruleId,
            dedupeToken,
            status
          };
        })
      });
    },
    resetAllRiskFilters: () => {
      searchParams.delete(SearchParams.RISK_STATUS);
      searchParams.delete(SearchParams.RULE);
      searchParams.delete(SearchParams.RESOURCE_TYPE);
      setSearchParams(searchParams);

      const riskStatus = getDefaultRiskStatus(undefined, someRiskStatus(memoizedRiskItems, RiskStatus.UNACKNOWLEDGED));
      setTableItems(memoizedRiskItems);
      filterRisks(riskStatus, allSelectOption, allSelectOption);
    },
    riskStatusFilterValue,
    ruleFilterValue,
    resourceTypeFilterValue,
    riskDisplayControlValue,
    filterRisks,
    filterRiskStatus: (option: OptionDefinition) => {
      setRiskStatusFilterValue(option);
      filterRisks(option, ruleFilterValue, resourceTypeFilterValue);
      updateSearchParams({ optionValue: option.value, searchParamName: SearchParams.RISK_STATUS });
    },
    filterRule: (option: OptionDefinition) => {
      setRuleFilterValue(option);
      filterRisks(riskStatusFilterValue, option, resourceTypeFilterValue);
      updateSearchParams({ optionValue: option.value, searchParamName: SearchParams.RULE });
    },
    filterResourceType: (option: OptionDefinition) => {
      setResourceTypeFilterValue(option);
      filterRisks(riskStatusFilterValue, ruleFilterValue, option);
      updateSearchParams({ optionValue: option.value, searchParamName: SearchParams.RESOURCE_TYPE });
    },
    updateRiskDisplay: (value: string) => {
      setRiskDisplayControlValue(value);
      updateSearchParams({ optionValue: value, searchParamName: SearchParams.RISK_DISPLAY });
    }
  };

  useEffect(() => {
    return () => {
      notificationsControls.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RisksContext.Provider value={riskContextProviderValue}>
      <Box margin='s'>
        <SpaceBetween size='s'>
          <Container header={<Header variant='h2'>Change Guardian Review</Header>}>
            {getReviewBadges(memoizedRiskItems, memoizedResourceRevisionItems)}
          </Container>
          <ReviewTabs
            riskItems={memoizedRiskItems}
            riskTableItems={tableItems}
            riskDisplayOption={riskDisplayControlValue}
            resourceRevisionItems={resourceRevisionItems}
            isLoadingRisks={isLoadingRisks}
            hasMoreRisks={hasMoreRisks || false}
            isLoadingResourceRevisions={isLoadingResourceRevisions}
            hasMoreResourceRevisions={hasMoreResourceRevisions || false}
            isMutating={isMutating}
            isFiltering={isFiltering}
          />
        </SpaceBetween>
      </Box>
    </RisksContext.Provider>
  );
};

export default Review;
