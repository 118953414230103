import React from 'react';
import { Button, Icon } from '@amzn/awsui-components-react';
import ResourceRevisionDiff from '../../resource-revisions/ResourceRevisionDiff';
import i18n from '../../../i18n';
import { SplitPanelContextProps } from '../../layout-housing/SplitPanel';
import { ResourceRevisionStructure } from '@amzn/change-guardian-approval-service-type-script-client/clients/changeguardianapprovalservice';

export interface RiskColumnDefinitionData {
  readonly severity: string;
  readonly riskId: string; // Composite key
  readonly resourceId: string;
  readonly ruleId: string;
  readonly ruleName: string;
  readonly dedupeToken: string;
  readonly status: string;
  readonly createdOn: string;
  readonly description: string;
  readonly approvalContainer: string;
  readonly reviewer: string;
  readonly reviewedOn: string;
}

export interface ReviewColumnDefinitionData {
  readonly reviewId: string;
  readonly status: string;
  readonly origin: string;
  readonly dateCreated: string;
}

export const resourceIdCell = (
  obj: RiskColumnDefinitionData | ResourceRevisionStructure,
  reviewId: string,
  splitPanelContext: SplitPanelContextProps
) => {
  const contentIdentifier = `diff-${reviewId}-${obj.resourceId}`;

  return (
    <Button
      variant='inline-link'
      onClick={() => {
        if (splitPanelContext.getContentIdentifier() === contentIdentifier) {
          splitPanelContext.reset();
          return;
        }

        splitPanelContext.setHeader(i18n.t('Change Details for') + ` ${obj.resourceId}`);
        splitPanelContext.setContent(
          <ResourceRevisionDiff resourceId={obj.resourceId} reviewId={reviewId} />,
          contentIdentifier
        );
        splitPanelContext.setOpen(true);
      }}
    >
      {obj.resourceId}
      <Icon name={splitPanelContext.getContentIdentifier() === contentIdentifier ? 'caret-up' : 'caret-down'} />
    </Button>
  );
};
